<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="4"></v-col>
      <v-col cols="12" sm="4">
        <v-card flat>
          <v-card-text>
            <h1 class="black--text text-center">Login</h1>
            <v-divider></v-divider>
            <v-form
                class="custom_form"
                ref="form"
                v-model="validationRules.valid"
                lazy-validation
            >
              <v-col cols="12" sm="12" class="p-0">
                <v-text-field clearable
                              outlined
                              :rules="validationRules.reqRules"
                              placeholder="User ID *"
                              dense v-model="email"
                              maxLength="50"></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" class="p-0">
                <v-text-field clearable
                              outlined
                              type="password"
                              :rules="validationRules.reqRules"
                              placeholder="Password *"
                              dense v-model="password"
                              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                              :type="show ? 'text' : 'password'"
                              @click:append="show = !show"
                              maxLength="15"></v-text-field>
              </v-col>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn :disabled="!validationRules.valid" @click="onSubmit" ref="l_submit" class="btn btn-primary btn-block" dark text>
              Login
            </v-btn>
          </v-card-actions>
          <v-col cols="12">
            <div
                v-if="errors != undefined && errors.length >= 0"
                role="alert"
                v-bind:class="{ show: errors.length }"
                class="alert fade alert-danger"
            >
              <div class="alert-text" v-for="(error, i) in errors" :key="i">
                {{ error.message }}
              </div>
            </div>
          </v-col>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<style lang="scss" scoped>
.spinner.spinner-right {
  padding-right: 3.5rem !important;
}
</style>

<script>
import {mapState} from "vuex";
import {LOGIN, CLEAR_AUTH_ERRORS} from "@/core/services/store/auth.module";
import Swal from "sweetalert2";

export default {
  name: "login",
  data() {
    return {
      email: '',
      password: '',
      show: false,
      validationRules: {
        valid: true,
        reqRules: [
          v => !!v || "This field is required."
        ]
      }
    };
  },
  methods: {
    onSubmit() {
      if (navigator.onLine) {
        if (this.$refs.form.validate()) {
          // clear existing errors
          this.$store.dispatch(CLEAR_AUTH_ERRORS);

          // set spinner to submit button
          const submitButton = this.$refs["l_submit"];
          this.validationRules.valid = false;

          // send login request
          this.$store
              .dispatch(LOGIN, {email: this.email, password: this.password})
              // go to which page after successfully login
              .then((data) => {
                if (data._metadata.outcomeCode === 0) {
                  if(data.records.user.role === 'dashboard'){
                    this.$router.push("dashboard");
                  } else {
                    this.$router.push("survey");
                  }
                } else {
                  this.validationRules.valid = true;
                }
              });
        }
      } else {
        Swal.fire('', 'Internet connection is required to login.', 'error');
      }
    },
    getLogo() {
      return process.env.BASE_URL + "media/logos/logo.jpg";
    }
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    })
  }
};
</script>
